import de from 'date-fns/locale/de'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import ja from 'date-fns/locale/ja'
import pl from 'date-fns/locale/pl'
import pt from 'date-fns/locale/pt'
import { BodyPartValue, CheckboxCustomValue } from 'value-constants'

import { PageId } from 'page-constants'

export const enum CurrentEnvironment {
  LOCAL = 'local',
  DEV = 'dev',
  PROD = 'prod',
  STAGE = 'stage',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 440,
  TABLET = 520,
  MAX_TABLET = 768,
  MIN_LAPTOP = 1024,
  LAPTOP = 1800,
  MAX_PHONE_HEIGHT = 667,
}

export const MAX_COLUMN_WIDTH = 360
export const KIT_MAX_COLUMN_WIDTH = 375
export const PAGE_HORIZONTAL_PADDING = 16
export const HEADER_HEIGHT_WITHOUT_PROGRESSBAR = 44

export const enum StripeFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const enum DynamicTitle {
  HEADLINE = 'headline',
  SUBTEXT = 'subtext',
}

export const CONTACT_FORM_LINKS = {
  [CurrentEnvironment.PROD]: 'https://account.nutrimate.fit/contact-form',
  [CurrentEnvironment.DEV]: 'https://account-nutrimate.gidev.xyz/contact-form',
  [CurrentEnvironment.STAGE]:
    'https://account-nutrimate.gistage.com/contact-form',
}
export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/d5808ebedf5357929eee86647da0a1d76abe111a9f5d3503d7eb9157aad897bd'
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const INTEGER_NUMBER_REGEX = /^[1-9]+[0-9]*$/

export const NumericKeypadRegex = /([0-9]{1}|Backspace|Enter)/
export const NumericKeypadFloatRegex = /([0-9.,]{1}|Backspace|Enter)/
export const FloatNumberRegex = /^([1-9]+[0-9]*|0{1})([.,][0-9]{0,1})?$/
export const ALPHABETIC_SYMBOLS_REGEXP = /[^\p{L}\p{Z}]+/gu
export const PERSONALIZATION_PLACEHOLDER_REGEXP = /{{(.*?)}}/g

export const enum LoginMethod {
  EMAIL = 'email',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
}

export const INITIAL_INPUT_VALUE = {
  value: '',
  isValid: false,
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum Color {
  PRIMARY = '#6fd09a',
  SECONDARY = '#e8ffee',
  LIGHT_GRAY = '#c4c4c4',
  SUCCESS = '#7ad718',
  ERROR = '#ff6169',
  WHITE = '#fff',
  GREY_WHITE = '#e9ecee',
  BLACK = '#17202a',
  BLACK_100 = '#000',
  BLACK_200 = '#2d3240',
  BLACK_300 = '#151920',
  BLACK_400 = '#292d3d',
  BLACK_500 = '##333a49',
  LIGHT = '#f5f6f7',
  LIGHT_BLUE = '#edeff0',
  DANGER = '#ff8484',
  DISABLED = '#898e97',
  ORANGE = '#f08b34',
  YELLOW_100 = '#fcd903',
  YELLOW_200 = '#efd701',
  ORANGE_100 = '#eaa300',
  ORANGE_200 = '#ff9900',
  VERY_LIGHT_BLUE = '#f7f9fc',
  DARK_BLUE = '#131d30',
  PINK = '#e0698c',
  PALE_RED = '#fff2f2',
  ORANGE_LIGHT = '#fff8f2',
  BLUE = '#978fff',
  GREY = '#d5d6d6',
  GREY_100 = '#2c3131',
  GREY_101 = '#626262',
  GREY_102 = '#a9a9a9',
  GREY_103 = '#d1f0df',
  GREY_104 = '#2f3643',
  GREY_110 = '#647069',
  GREY_111 = '#b0b4bc',
  GREY_120 = '#f1f5f9',
  GREY_130 = '#f2f3f5',
  GREY_200 = '#f1f1f3',
  GREY_300 = '#d1d5db',
  GREY_400 = '#e5e7eb',
  GREY_500 = '#b8bbc1',
  GREY_600 = '#81848c',
  GREY_700 = '#fafaff',
  GREY_800 = '#e9e9e9',
  GREY_900 = '#efeff6',
  GREY_1000 = '#cacaca',
  GREY_LIGHT = '#f5f5f5',
  GREEN_100 = '#f1fff8',
  GREEN_200 = '#d1fae5',
  GREEN_300 = '#98bf36',
  GREEN_400 = '#a9cd50',
  GREEN_500 = '#f0f4eb',
  GREEN_600 = '#eef5dc',
  GREEN_700 = '#f2f6ee',
  GREEN_750 = '#90b632',
  GREEN_800 = '#7ba60f',
  RED_100 = '#bc2222',
  RED_200 = '#ff4329',
  RED_300 = '#f83b00',
  RED_400 = '#e74c3c',
  RED_500 = '#ed8e70',
  BEIGE_100 = '#fbf6ee',
}

export const enum Gradient {
  RADIAL_RED_YELLOW = '104% 760.5% at 15.36% 124%,#ff0031 0%,#ffbc14 100%',
  LINEAR_RED_YELLOW = '73deg, #ff0031 -1.71%, #ffbe1b 111.15%',
  LINEAR_RED_YELLOW_LIGHT = '88deg, #FBD3DE -0.53%, #FBEDDB 108.08%',
  LINEAR_RED_CORAL_LIGHT = '87deg, #F2D6DA -4.33%, #FAE0E4 111.17%',
  LINEAR_RED_CORAL = '73deg, #C40202 -1.71%, #FF5659 111.15%',
  LINEAR_VIOLET_BLUE = '73deg, #7B00FF -1.71%, #1BE4FF 111.15%',
  LINEAR_VIOLET_BLUE_LIGHT = '87deg, #E6D6FF -3.7%, #DAF6FE 119.47%',
}

export const enum Emojis {
  BICEPS = '\uD83D\uDCAA',
  SOUP = '\uD83C\uDF72',
  SUPPORT = '\uD83E\uDEC2',
  CHART = '\uD83D\uDCC8',
  FINGER_DOWN_POINTER = '\uD83D\uDC47',
  FINGER_UP_POINTER = '\u261d\ufe0f',
  STAR_EYES = '\uD83E\uDD29',
  STAR = '\u2b50',
  WAVING_HAND = '\uD83D\uDC4B',
  SMILING_FACE_WITH_3_HEARTS = '\uD83E\uDD70',
  OK_HAND = '\uD83D\uDC4C',
  THUMBS_UP = '\uD83D\uDC4D',
  PARTY = '\ud83c\udf89',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export const enum UpsellProduct {
  BOTH = 'pdf_FitnessAndSelfcare',
  FITNESS = 'pdf_fitness',
  SELF_CARE = 'pdf_selfcare',
  SIMPLE_CHANGES = 'pdf_SimpleChanges',
  SUGAR_FREE = 'pdf_SugarFreeDesserts',
  CHANGES_SUGAR = 'pdf_WeightLossGuides',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum OutbrainEventName {
  PAGE_VIEW = 'PAGE_VIEW',
  ADD_TO_CART = 'Add To Cart',
  CHECKOUT = 'Checkout',
  PURCHASE = 'Purchase',
  COMPLETE_REGISTRATION = 'Lead',
}

export const enum TaboolaEventName {
  PAGE_VIEW = 'page_view',
  ADD_TO_CART = 'add_to_cart',
  CHECKOUT = 'start_checkout',
  PURCHASE = 'make_purchase',
  COMPLETE_REGISTRATION = 'complete_registration',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum Cohort {
  MF_INTRO = 'mf_intro',
  MF_INTRO_2 = 'mf_intro2',
  MF_INTRO_SALE = 'mf_intro_sale',
  MF_FLOW_2 = 'mf_flow2',
  MF_FLOW_2_INTRO_2 = 'mf_flow2intro2',
  MF_MEN_FLOW = 'mf_menflow',
  MF_WOMEN_AGE = 'mf_womenage',
  MF_WOMEN_45 = 'mf_women_45',
  MF_KETO = 'mf_keto',
  MF_NOSUGAR = 'mf_nosugar',
  MF_MEALPLAN = 'mf_mealplan',
  MF_SARAH_JAKE = 'mf_sarah_jake',
  MF_INFLUENCER = 'mf_influencer',
  MF_REFUND = 'mf_refund',
  MF_SCANNER = 'mf_scanner',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  CHECKOUT = 'checkout',
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPSELL = 'upsell',
  ONE_TIME = 'one_time',
}

export const enum SubscriptionTags {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
  UPSELL_LONG = 'upsell_long',
  INAPP_FITNESS_SELFCARE = 'inapp_fitness_selfcare',
  INAPP_CHANGES_DESSERTS = 'inapp_changes_desserts',
}

export const UPSELL_PAYWALL_PAGES = [
  PageId.UPSELL_PAYWALL_1,
  PageId.UPSELL_PAYWALL_2,
  PageId.UPSELL_PAYWALL_3,
]

export const POST_PAYMENT_PAGES = [
  ...UPSELL_PAYWALL_PAGES,
  PageId.FINISHING_TOUCHES,
  PageId.LOGIN,
  PageId.DOWNLOAD,
]

export const enum MeasurementSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export const COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM = [
  'AU',
  'CA',
  'NZ',
  'US',
  'ZA',
]

export const enum ScreenName {
  ONBOARDING = 'onboarding',
  CANCEL_OFFER = 'cancel_offer',
  UPSELL_SWITCHER = 'upsell_switcher',
  CHECKOUT = 'checkout',
  UPSELL_COACHING = 'upsell_coaching',
  UPSELL_COACHING_CANCEL = 'upsell_coaching_cancel',
  UPSELL_LIST = 'upsell_list',
}

export const enum PlanAddition {
  MEAL_PLAN = 'Meal plan',
  PREMIUM = 'Premium',
  UPSELL = 'Upsell',
}

export const IN_APP_PRODUCT_EVENT_NAMES_MAP = {
  [UpsellProduct.BOTH]: 'guide_bundle',
  [UpsellProduct.FITNESS]: 'dream_body_guide',
  [UpsellProduct.SELF_CARE]: 'self_care_guide',
  [UpsellProduct.SUGAR_FREE]: 'desserts_guide',
  [UpsellProduct.SIMPLE_CHANGES]: 'simple_changes_guide',
  [UpsellProduct.CHANGES_SUGAR]: 'weightloss_guide',
}

export const enum Language {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
  FR = 'fr',
  DE = 'de',
  JA = 'ja',
  PL = 'pl',
  IN_CONTEXT = 'af',
}

export const SUPPORTED_LOCALES: string[] = [
  Language.EN,
  Language.ES,
  Language.PT,
  Language.JA,
  Language.FR,
  Language.DE,
  Language.PL,
]

export const DATEPICKER_SUPPORTED_LOCALES = {
  [Language.EN]: en,
  [Language.ES]: es,
  [Language.PT]: pt,
  [Language.FR]: fr,
  [Language.DE]: de,
  [Language.JA]: ja,
  [Language.PL]: pl,
}

export const EMPTY_OLD_PRICE = {
  fullPrice: 0,
  amountOfDiscount: 0,
  weekly: 0,
  daily: 0,
  monthly: 0,
  yearly: 0,
  percentOfDiscount: 0,
}

export const COHORTS_BASED_ON_FLOW2 = [
  Cohort.MF_FLOW_2,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_MEN_FLOW,
]

export const PUBLIC_PAGES = [
  PageId.ENTER_EMAIL_1,
  PageId.ENTER_EMAIL_2,
  PageId.KIT_ENTER_EMAIL_1,
  PageId.EMAIL_CONSENT_1,
  PageId.KIT_EMAIL_CONSENT_1,
  PageId.RESULT_1,
  PageId.CREATING_PLAN_1,
  PageId.CREATING_PLAN_3,
  PageId.PRESALE_1,
  PageId.PRESALE_2,
  PageId.KIT_PRESALE_1,
]

export const TARGET_AREA_FULL_LIST = [
  BodyPartValue.ARMS,
  BodyPartValue.CHEST,
  BodyPartValue.BELLY,
  BodyPartValue.HIPS,
  BodyPartValue.LEGS,
]

export const enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

export const enum AppLinks {
  TOU_LINK = `/${PageId.TERMS_OF_USE}`,
  PP_LINK = `/${PageId.PRIVACY_POLICY}`,
}

export const enum HOT_JAR_CLIENT_ID {
  PROD = '3903825',
  DEV = '3907148',
}

export const enum CountryCode {
  ARGENTINA = 'ar',
  COLOMBIA = 'co',
  PERU = 'pe',
  CHILE = 'cl',
  DOMINICAN_REPUBLIC = 'do',
  URUGUAY = 'uy',
  ECUADOR = 'ec',
  GUATEMALA = 'gt',
  VENEZUELA = 've',
  PARAGUAY = 'py',
  BOLIVIA = 'bo',
  NICARAGUA = 'ni',
  HONDURAS = 'hn',
  COSTA_RICA = 'cr',
  PANAMA = 'pa',
  EL_SALVADOR = 'sv',
  BRAZIL = 'br',
  INDIA = 'in',
  SOUTH_AFRICA = 'za',
  NIGERIA = 'ng',
  KENYA = 'ke',
  ZAMBIA = 'zm',
  TANZANIA = 'tz',
  ETHIOPIA = 'et',
  ZIMBABWE = 'zw',
  SENEGAL = 'sn',
  NAMIBIA = 'na',
  POLAND = 'pl',
}

export const FEATURE_KEYS = {
  fas_1057_cancel_offer: 'fas_1057_cancel_offer',
  fas_530_new_upsell: 'fas_530_new_upsell',
  fas_801_dynamic_discount: 'fas_801_dynamic_discount',
  fas_928_primer_paypal_es: 'fas_928_primer_paypal_es',
  fas_1055_checkout_in_modal: 'fas_1055_checkout_in_modal',
} as const

export const MEN_COHORTS = [Cohort.MF_MEN_FLOW]
export const WOMEN_COHORTS = [Cohort.MF_WOMEN_AGE, Cohort.MF_WOMEN_45]
export const APP_NAME = 'NutriMate'
export const API_APP_NAME = 'myfast'
export const CENTS_IN_DOLLAR = 100
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'custom_token'
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const MAXIMUM_WEIGHT_LOSS_PERCENTAGE = 0.00143
export const LOWER_BMI_THRESHOLD_V1 = 10
export const UPPER_BMI_THRESHOLD = 45
export const LANG_QUERY_PARAM = 'lang'
export const INITIAL_LANGUAGE = Language.EN
export const VAT_INCLUDED_COUNTRIES = ['US', 'MX', 'ES', 'BR', 'FR', 'DE', 'PL']
export const CHECKBOX_CUSTOM_VALUES = [CheckboxCustomValue.NONE as string]
export const PERCENTAGE_MULTIPLIER = 100
export const TOU_AND_PP_LINKS: string[] = [AppLinks.TOU_LINK, AppLinks.PP_LINK]
export const DEFAULT_DYNAMIC_DISCOUNT_AMOUNT = 50
export const INFLUENCER_NAME_SESSION_STORAGE_KEY = 'influencer_name'
export const DEFAULT_TIMER_CONTENT = '00'
export const TOTAL_USERS = 1_100_000
export const CANCEL_OFFER_BASE_ID = 'cancel_offer'
export const CANCEL_OFFER_QUESTION_BASE_ID = 'cancel_offer_question'
export const CHECKOUT_BASE_ID = 'checkout'
export const MODAL_OUTER_INDENT = 40

export const MIN_MAX_WEIGHT = {
  MIN_WEIGHT_KG: 21,
  MAX_WEIGHT_KG: 149,
  MIN_WEIGHT_LB: 46,
  MAX_WEIGHT_LB: 328,
}
