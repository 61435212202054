import React, { FC, useCallback, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import { sendUserEmailConsentAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohortToUse,
  selectIsFetching,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useUserStatus } from 'hooks/useUserStatus'

import { TPageProps } from 'models/common.model'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { Cohort, Language } from 'root-constants'

import { StyledEmailConsent as S } from './EmailConsentV1.styles'

export const EmailConsentV1: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const userStatus = useSelector(selectUserStatus)
  const isFetching = useSelector(selectIsFetching)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { title } = useDynamicOBConfig()
  const availableRoute = useUserStatus()
  const firstPageId = usePageInfo().steps[0].id

  const { titleTransKey, noButtonTransKey } = useMemo(
    () => ({
      titleTransKey:
        cohortToUse === Cohort.MF_WOMEN_45
          ? 'onboarding.emailConsentV1.titleV2'
          : 'onboarding.emailConsentV1.titleV1',
      noButtonTransKey:
        cohortToUse === Cohort.MF_WOMEN_45
          ? 'actions.dontWantReceiveTips'
          : 'actions.noThanks',
    }),

    [cohortToUse],
  )

  useLayoutEffect(() => {
    if (!isFetching) {
      switch (true) {
        case userStatus?.hasSubscription:
          goTo({ pathname: availableRoute, search })
          break
        case !userStatus?.email.hasEmail:
          goTo({ pathname: firstPageId, search })
          break
      }
    }
  }, [search, userStatus, availableRoute, isFetching])

  const handleContinue = useNextStep({
    pageId,
    question: t(titleTransKey, { lng: Language.EN }),
    nextPagePath,
  })

  const sendUserEmailConsent = useCallback(
    async (consented: boolean) => {
      await dispatch(sendUserEmailConsentAction({ consented }))
      googleAnalyticsLogger.logTestConversion()
      handleContinue(
        t(consented ? 'actions.yesImIn' : noButtonTransKey, {
          lng: Language.EN,
        }),
      )
    },
    [dispatch, handleContinue, t, noButtonTransKey],
  )

  const handleYes = useCallback(
    () => sendUserEmailConsent(true),
    [sendUserEmailConsent],
  )

  const handleNo = useCallback(
    () => sendUserEmailConsent(false),
    [sendUserEmailConsent],
  )

  if (isFetching) {
    return <Spinner />
  }

  return (
    <S.Container>
      <S.Title marginBottom={24}>
        {title || <Trans i18nKey={titleTransKey} />}
      </S.Title>

      <S.ButtonsContainer>
        <Button onClick={handleYes}>{t('actions.yesImIn')}</Button>
        <S.NoButton onClick={handleNo}>{t(noButtonTransKey)}</S.NoButton>
      </S.ButtonsContainer>
    </S.Container>
  )
}
